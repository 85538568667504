import logo from "./logo.svg";
import "./App.css";
import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { abi, address } from "./contract.js";
import Marquee from "react-fast-marquee";

function App() {
  const [contract, setContract] = useState({});
  const [supply, setSupply] = useState("connect to view mint count");
  const [click, setClick] = React.useState(0);
  const [buttonText, setButtonText] = React.useState("Sold Out");
  var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const [stealthText, setStealthText] = React.useState("");

  useEffect(() => {
    if (isMobile) {
      setStealthText("");
    }
  });

  const [tx, setTX] = useState("");

  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider, // required
      options: {
        rpc: {
          1: "https://rpc.ankr.com/eth",
        },
      },
    },
  };

  const web3Modal = new Web3Modal({
    network: "mainnet", // optional
    cacheProvider: true, // optional
    providerOptions, // required
    theme: {
      background: "#ffffff",

      hover: "#c4c4c4",
    },
  });

  const loadWeb3 = async () => {
    if (window.web3) {
      let accounts;
      let account;
      const web3modalprovider = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(web3modalprovider);
      const signer = provider.getSigner();

      const network = await provider.getNetwork();

      if (provider["provider"]["bridge"]) {
        await provider
          .send("eth_sign", [provider["provider"]["accounts"][0], "0xdeadbeaf"])
          .then((res) => {
            account = provider["provider"]["accounts"][0];
          })
          .catch((err) => console.log(err));
      } else {
        accounts = await provider.send("eth_requestAccounts", []);
        account = accounts[0];
      }
      const networkID = network["chainId"];

      if (networkID != 1) {
        window.alert("Please switch to Mainnet!");
      }

      setButtonText("Sold Out");
      setContract(new ethers.Contract(address, abi, signer));
      const CONTRACT = new ethers.Contract(address, abi, signer);
      let _contract = new ethers.Contract(address, abi, provider);
      return CONTRACT;
    } else {
      window.alert("Please switch to Ethereum compatible browser with metamask installed");
    }
  };

  const connect = async () => {
    web3Modal.clearCachedProvider();
    if (buttonText == "Connect") {
      await loadWeb3().catch((err) => console.log(err));
    } else {
      await mintFudder().catch((err) => console.log(err));
    }
  };

  const mintFudder = async () => {
    if (click >= 1 && click <= 10) {
      if (buttonText == "Connect") {
        try {
          let _contract = await loadWeb3();

          const salesPrice = (click * 0.069 * 10 ** 18).toString();
          const gasLimitAsHex = await _contract.estimateGas["mint(uint256)"](click, { value: salesPrice });
          const safeGasLimit = Math.floor(parseInt(gasLimitAsHex) * 1.2);

          const tx = await _contract.mint(click, {
            value: salesPrice.toString(),
            gasLimit: safeGasLimit,
          });

          setTX("https://etherscan.io/tx/" + tx.hash);
        } catch (err) {
          console.log(err);
          console.log(err["message"]);
          document.getElementById("erroralert").innerHTML = "Error: Check Console For Details";
        }
      } else {
        try {
          const salesPrice = (click * 0.069 * 10 ** 18).toString();
          const gasLimitAsHex = await contract.estimateGas["mint(uint256)"](click, { value: salesPrice });
          const safeGasLimit = Math.floor(parseInt(gasLimitAsHex) * 1.2);

          const tx = await contract.mint(click, {
            value: salesPrice.toString(),
            gasLimit: safeGasLimit,
          });

          setTX("https://etherscan.io/tx/" + tx.hash);
        } catch (err) {
          console.log(err);
          console.log(err["message"]);
          document.getElementById("erroralert").innerHTML = "Error: Check Console For Details";
        }
      }
    } else if (buttonText == "Sold Out") {
    } else if (click == 0 || click > 5) {
      window.alert("Incorrect mint amount: Min 1, Max 10 per TX");
    }
  };
  function clickUp() {
    document.getElementById("erroralert").innerHTML = "";
    if (click < 5) {
      setClick(click + 1);
    }
  }

  function clickDown() {
    document.getElementById("erroralert").innerHTML = "";
    if (click > 0) {
      setClick(click - 1);
    }
  }
  return (
    <div className="App">
      <div className="nav-container">
        {/* <img src="assets/nav-logo.png" alt="" className="nav-logo" /> */}
        <div className="nav-logo">
          <h1 className="nav-logo-text">An Americana</h1>
          <h1 className="smaller-text">&copy;&nbsp;</h1>
          <h1 className="nav-logo-text"> Production</h1>
        </div>
        <h1 className="nav-text">
          Free to&nbsp;
          <a href="https://twitter.com/probablynthng" target="_blank" className="underline">
            Probably Nothing
          </a>
          &nbsp;holders
        </h1>
      </div>
      <div class="home-container">
        <div class="div1">
          <h1 className="home-text something">Something</h1>
        </div>
        <div class="div2">
          <h1 className="home-text token">Token</h1>
        </div>

        <div class="div4">
          <div className="text-boxes">
            <div className="top-text">
              <p className="top">0.069 ETH Mint Price</p>
              <p className="top">25 Tokens Per Wallet</p>
              <p className="top">9500 Total Supply</p>
              <p className="top">500 for Giveaways</p>
            </div>
            <div className="bottom-text">
              <p className="bottom">Early Access and discounts to Americana</p>
              <p className="bottom">First Web3 marketplace for real objects</p>
              <p className="bottom">Investor and partnership announcement this May, marketplace launch this Summer</p>
            </div>
          </div>
        </div>
        <div class="div3">
          {buttonText == "Connect" && <p className="clickSelector">{stealthText}</p>}
          {/* {buttonText !== "Connect" && (
            <div className="clickSelector">
              <span className="decrement" onClick={() => clickDown()}>
                -
              </span>
              <p>{click}</p>
              <span className="increment" onClick={() => clickUp()}>
                +
              </span>
            </div>
          )} */}
          {buttonText == "Sold Out" && (
            <div className="clickSelector">
              <img src="assets/openseaLogo.png" alt="" className="openseaLogo" />
              <p className="bottom">
                <a href="https://opensea.io/collection/somethingtoken" target="_blank" class="underline-on-hover">
                  View On Opensea
                </a>
              </p>
            </div>
          )}
          <div className="connectButton" onClick={() => connect()}>
            <p>{buttonText}</p>
            <img src="assets/rightCarret.png" alt="" className="carret" />
          </div>
          <div className="erroralert" id="erroralert"></div>

          <h1 className="mobile-bottom-text">
            Free to&nbsp;
            <a href="https://twitter.com/probablynthng" target="_blank" className="underline">
              Probably Nothing
            </a>
            &nbsp;holders
          </h1>
        </div>
      </div>
      <div className="large-scroll-text">
        <Marquee loop={0} delay={0.1} gradient={false} speed={100}>
          <span>SomethingToken</span>
          <span>SomethingToken</span>
          <span>SomethingToken</span>
          <span>SomethingToken</span>
          <span>SomethingToken</span>
          <span>SomethingToken</span>
          <span>SomethingToken</span>
          <span>SomethingToken</span>
          <span>SomethingToken</span>
          <span>SomethingToken</span>
          <span>SomethingToken</span>
          <span>SomethingToken</span>
          <span>SomethingToken</span>
          <span>SomethingToken</span>
          <span>SomethingToken</span>
          <span>SomethingToken</span>
          <span>SomethingToken</span>
          <span>SomethingToken</span>
          <span>SomethingToken</span>
          <span>SomethingToken</span>
          <span>SomethingToken</span>
          <span>SomethingToken</span>
          <span>SomethingToken</span>
          <span>SomethingToken</span>
          <span>SomethingToken</span>
          <span>SomethingToken</span>
          <span>SomethingToken</span>
          <span>SomethingToken</span>
          <span>SomethingToken</span>
          <span>SomethingToken</span>
          <span>SomethingToken</span>
          <span>SomethingToken</span>
          <span>SomethingToken</span>
        </Marquee>
      </div>
    </div>
  );
}

export default App;
